/* .dashright{
    max-width: 86%;
    max-height: 100vh;
    margin-left:14%;
} */

/* .main-area{
    background-color: white;
} */

.top-head {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 1%;
}

.heading {
  font-size: xx-large;
}

.apply_filter {
  height: fit-content;
  color: white;
  background-color: white;
  color: #00acfb;
  border: 1px solid #00acfb;
  padding: 16px 24px;
  border-radius: 8px;
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 500;
}

.apply_filter:hover {
  background-color: #00acfb;
  color: white;
}
.filterHover{
  background-color: #00acfb;
  color: white;
}
.searchBox {
  max-width: 20vw;
}

.right_filters {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

/* .filter_dropdown{
    background-color: black;
   
} */

.date_filter_box {
  display: flex;
  flex-direction: row;
}

.filter_button {
  height: fit-content;
  background-color: white;
  color: #00acfb;
  border: 1px solid #00acfb;
  padding: 16px 24px;
  border-radius: 8px;
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 500;
}

.export_button {
  height: fit-content;
  background-color: #00acfb;
  color: white;
  border: 1px solid #00acfb;
  padding: 16px 24px;
  border-radius: 8px;
  font-size: 20px;
  font-family: var(--font-roboto);
  font-weight: 500;
}

.export_button:hover {
  color: #00acfb;
  background-color: white;
  border-color: #00acfb;
}

.welcome_button {
  background: #ebf9ff;
  font-family: 'Roboto', 'sans-serif';
  color: #00acfb;
  min-width: 10vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fromDate {
  min-height: 5vh;
  background-color: white;
  border: none;
  border-right: 2px solid #e3e3e3;
}

.toDate {
  min-height: 5vh;
  background-color: white;
  border: none;
}
/*

.toDate{
    margin-left: 70%;
    min-height: 5vh;
    background-color: white;
    color: rgb(0, 0, 0);
    border: none;
} */

.quickFilters {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 60px;
  /* margin-bottom: -2%; */
}

.option {
  display: flex;
  gap: 0.5vw;
}

.selectAllFilters {
  height: fit-content;
  min-width: fit-content;
  color: white;
  background-color: white;
  color: #00acfb;
  border: 1px solid #00acfb;
  padding: 16px 24px;
  border-radius: 8px;
  font-size: 20px;
  font-family: var(--font-roboto);
  font-weight: 500;
}

.selectAllFilters:hover {
  color: white;
  background-color: #00acfb;
}

.messageCards {
  width: 40%;
  padding: 5px;
  background: #f7f9fb;
  border: 1px solid #c4cdd5;
  box-shadow: 2px 2px 24px rgba(196, 205, 213, 0.12);
  max-height: 18%;
  width: 40%;
  overflow-y: scroll;
}

.cardHead {
  height: fit-content;
}

.cardTopic {
  display: flex;
  gap: 0.5vw;
}

.cardDownArrow {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
}

.cardTopic {
  columns: black;
  font-size: larger;
  font-weight: bold;
  font-family: 'Roboto', 'sans-serif';
  /* align-items: center;     */
}

.cardTeleLink{
  margin-left: auto;
}
.cardTeleLink{
    outline: None;
}

.cardTeleLink .cls_unseen{
  background-color: #EBF9FF;
  outline: None;

}

.cardTeleLink .cls_seen{
  background-color: #f7f9fb;
  outline: None;
}

.messageDate {
  color: #898f9a;
  margin-left: 1.3vw;
  margin-top: -1%;
}

.mainCardDetails {
  padding: 1vw;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.cardMessage {
  margin-top: 2vh;
  width: 100%;
}

.cardTags {
  width: 100%;
}

.Tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5vw;
  align-items: center;
}

.tagBox {
}

.cardMobile {
  width: 100%;
}

.mobileDets {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5vw;
}

/* .expandableRow1{
    padding: 1vw;
    display: flex;
    gap: 2vw;
    align-items:stretch;
} */

.MailDets {
  width: 100%;
}

.cardEmail {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5vw;
}

.MailDets {
  width: 100%;
}

.cardEmail {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5vw;
}

.cardCrypto {
  width: 100%;
}

.cryptoBoxes {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5vw;
}

.cryptoBox {
  background: #ebf9ff;
  padding-inline: 2px;
}

.cardLinks {
  width: 100%;
}

.cardLink {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5vw;
}

.expandableRow2 {
  /* padding: 1vw;  */
}

.pagination_container {
  position: static;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}

.nav_container {
  margin-left: auto;
}

.display_pages {
  color: #b7b7b7;
  font-size: medium;
  margin-left: auto;
}

.detsHead {
  display: flex;
  align-items: end;
  font-weight: bold;
}

.fileHead {
  min-width: max-content;
  font-weight: bold;
}

.cardFile {
  background-color: white;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3%;
  border: 1px solid #c4cdd5;
  padding: 1%;
}

.file_s {
  display: flex;
  flex-direction: row;
  column-gap: 1%;
  width: 100%;
  align-items: center;
}

.file {
  display: flex;
  flex-direction: row;
  column-gap: 1%;
  min-width: max-content;
  align-items: center;
}

.fileType {
  min-width: max-content;
  border: 1px solid #ff8f8f;
  border-radius: 16px;
  color: #ff8f8f;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding-left: 0.5%;
    padding-right: 0.5%;
    width: 27%; */
  padding-inline: 12%;
  height: fit-content;
}

.fileDownload {
  border: 2px solid #00acfb;
  border-radius: 16px;
  color: #00acfb;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding-left: 0.1%;
    padding-right: 0.1%; */
  padding-inline: 2%;
  /* width: 90%; */
  height: fit-content;
  min-width: max-content;
  padding-inline: 6px;
  margin-left: auto;
}

.fileContent {
  /* overflow: hidden;
    text-overflow: ellipsis; */
  min-width: max-content;
}

.singleFile {
  display: flex;
  gap: 2%;
}

.cardOpenBtn {
  background: none;
}

.emailBox {
  background: #ebf9ff;
  padding-inline: 2px;
}

.linkBox {
  background: #ebf9ff;
  padding-inline: 2px;
}

.phoneBox {
  background: #ebf9ff;
  padding-inline: 2px;
}

.fileContent {
  background: #ebf9ff;
  padding-inline: 2px;
}

.tagBox {
  border: 1px solid #00acfb;
  padding-inline: 4px;
  color: #00acfb;
  border-radius: 16px;
}

.MessageText {
  overflow-wrap: break-word;
}

.entryBoxMessages {
  width: 50%;
  border: 1px solid #ededef;
  z-index: 3;
}

.filter_button:hover {
  background-color: white;
  color: #00acfb;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
}

.filterDropdown {
  background-color: white;
  width: 20%;
  margin-left: -7.6%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 1%;
  margin-top: 0.5%;
}

.filterButtonDiv {
  display: flex;
  flex-direction: column;
}

.row1FilterDropdown {
  display: flex;
  align-items: center;
}

.dropdownHeading {
  font-size: x-large;
  font-weight: bolder;
}

.ResetFilter {
  width: 25%;
  border-radius: 10px;
  background-color: white;
  color: #ff8f8f;
  font-size: large;
  border: 1px solid #ff8f8f;
  margin-left: auto;
}

.dropdownSubhead {
  color: #b7b7b7;
  font-size: small;
  margin-top: -2%;
}

.dropdownHead2 {
  font-size: large;
  font-weight: bold;
  margin-top: 6%;
  margin-bottom: 3%;
}

.filterOptions {
  display: flex;
  gap: 4px;
  align-items: center;
}

.optionContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 3%;
  row-gap: 15px;
}

.dropdownFilterSetters {
  display: flex;
  margin-top: 8%;
  justify-content: center;
  gap: 10%;
}

.dropDownbtn1 {
  width: 25%;
  border-radius: 10px;
  font-size: medium;
  height: 40px;
}

.dropDownbtn2 {
  width: 25%;
  border-radius: 10px;
  font-size: medium;
  height: 40px;
  color: #00acfb;
  background-color: white;
  border: 1px solid #00acfb;
}

.dropdownContainer {
  background-color: white;
  width: 18%;
  margin-left: -8%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding-inline: 1%;
  padding-block: 1%;
  overflow-x: auto;
  overflow-y: auto;
  /* min-height: 50%; */
  margin-top: 0.5%;
}

.dropdownHead {
  font-size: x-large;
  font-weight: bolder;
}

.dropdownSubheads {
  font-size: large;
  font-weight: bolder;
  margin-bottom: 1vh;
}

.dropdownRows {
  padding-inline-start: 2%;
}
.dropdownBody {
  margin-top: 4%;
  display: flex;
  gap: 2%;
  justify-content: space-between;
}
.dropdownContent {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.checks {
  display: flex;
  gap: 6px;
}

.dropdownDescription {
  color: #898f9a;
}

.dropdownButtons {
  margin-top: 6%;
  display: flex;
  justify-content: center;
  gap: 10%;
}

.apply {
  width: fit-content;
  padding-inline: 10px;
  padding-block: 6px;
  height: fit-content;
  font-size: large;
  border-radius: 6px;
  border: 1px solid #00acfb;
}
.apply:hover {
  color: #00acfb;
  background-color: white;
}

.cancel {
  width: fit-content;
  padding-inline: 10px;
  padding-block: 6px;
  height: fit-content;
  font-size: large;
  border-radius: 6px;
  color: #898f9a;
  border: 1px solid #898f9a;
  background-color: white;
}

.cancel:hover {
  color: white;
  background-color: #898f9a;
}

.paginateMessages {
  position: fixed;
  bottom: 0;
  right: 0;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  width: calc(100% - 250px);

  background-color: #fff;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2vh;
}

.message_card_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.message_cards {
  flex-basis: 400px;
  /* max-width: 49%; */
  flex-grow: 1;
  height: 25vh;
  overflow-y: scroll;
  padding-block: 1.5vh;
  padding-inline: 3.5vh;
  background-color: #f7f9fb;
  border: 1px solid #c4cdd5;
  box-shadow: 2px 2px 24px rgba(196, 205, 213, 0.12);
  border-radius: 8px;
}

.message_cards_unseen{
  background-color: #EBF9FF;
}

/* .message_cards_unseen{

} */

.message_Date {
  color: #b7b7b7;
  font-weight: bolder;
}

.main_Card_Details {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.message_cards_single_details {
  padding: 2%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: auto;
  max-height: 90vh;
  overflow: auto;
  z-index: 9;
}
