@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i');

.page_container_jobs {
  margin-left: auto;
  padding: 72px 36px;
  width: calc(100% - 250px);
}

.main_heading_container_jobs {
  display: flex;
  align-items: center;
  gap: 2%;
  width: 100%;
}

.Head1_jobs {
  width: 17%;
  font-size: xx-large;
  font-weight: bolder;
  min-width: fit-content;
}

.search_form_container {
  width: 65%;
}

.search_form {
  display: flex;
  width: 100%;
}

.formSearchBtn {
  min-width: 10%;
  max-width: fit-content;
  padding-inline: 1%;
  margin-left: 1%;
  border-radius: 8px;
  font-size: medium;
  height: 4vh;
}

.form_dropdown {
  width: 'max-content';
  max-width: 20%;
  height: 4vh;
}

.form_input_bar {
  width: 60%;
  height: 4vh;
}

.job_body_jobs {
  width: 100%;
  /* padding-inline: 2%; */
}

.table_container_jobs {
  margin-top: 4%;
}

.pagination_container_jobs {
  position: static;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.jobActionBtn {
  width: fit-content;
  padding-inline: 2%;
  font-family: 'Open Sans';
  font-size: medium;
  border-radius: 4px;
  color: #00acfb;
  border: 2px solid#00ACFB;
  background-color: white;
  height: max-content;
  position: relative;
}

.jobActionBtn > img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: -25px;
  width: 18px;
  height: 18px;
}
.JobDetailsPopup {
  min-width: 20%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding-inline: 1%;
  padding-block: 1%;
  border: 1px solid #919eab;
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
}

.jobsPopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jobsPopHeaderMessage {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: large;
}

.jobsHeaderCloseBtn {
  background-color: rgba(255, 255, 255, 0);
  color: #ff5858;
  width: fit-content;
}

.job_file_name {
  margin-top: 2%;
  background-color: #ebf9ff;
  font-family: 'Open Sans';
  padding-inline: 2%;
}

.job_media_downloadBtn {
  width: fit-content;
  font-size: medium;
  margin-top: 4%;
  height: fit-content;
  padding-inline: 3%;
  padding-block: 1%;
  border-radius: 4px;
}

.job_link_internal_page {
  text-decoration: none;
  font-family: 'Open Sans';
  font-size: medium;
  color: #2ce38b;
  border: 1px solid #2ce38b;
  background-color: rgba(255, 255, 255, 0);
  padding-inline: 3%;
  padding-block: 1%;
  border-radius: 4px;
}
.job_link_ {
  text-decoration: none;
  font-family: 'Open Sans';
  font-size: medium;
  color: #00ACFB;
  border:1px solid #00ACFB;
  background-color: rgba(255, 255, 255, 0);
  padding-inline: 3%;
  padding-block: 1%;
  border-radius: 4px;
}
.job_detail_time_elapsed {
  font-family: 'Open Sans';
  font-size: medium;
  color: #919eab;
}

.hoverable-cell:hover {
  cursor: pointer;

  color: #223036;
}
