.dashleft {
  position: fixed;
  width: 250px;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  font-family: 'Roboto', sans-serif;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  z-index: 9999999;
}

.head-left {
  margin-top: 20%;
  text-align: center;
  font-size: xx-large;
  font-weight: bolder;
  letter-spacing: 1.5px;
}

.div-left-navs {
  align-items: center;
  margin-top: 25%;
  display: flex;
  flex-direction: column;
  height: 65%;
}

.navs {
  padding: 24px;
  background-color: white;
  display: flex;
  gap: 10px;
  /* align-items: start; */
  letter-spacing: 1.2px;
  font-family: 'Roboto', 'sans-serif';
  color: black;
  width: 100%;
  /* justify-content: start; */
  font-size: 16px;
  height: auto;
}

.navs.active {
  background-color: #ebf9ff;
  color: #00acfb;
}

/* .navs:focus{
    background: #EBF9FF;
} */

/* .navs:not(.active):hover {
  cursor: pointer;
  background: #00acfb;
  color: white;
  font-weight:bold;
    font-size: large;
} */

.top-btns {
  height: 70vh;
  width: 100%;
}

.logout-btn {
  padding: 15px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  height: auto;
  padding: 16px 42px;
  letter-spacing: 1.2px;
  font-family: 'Roboto', 'sans-serif';
  font-size: large;
  font-weight: bolder;
  color: #00acfb;
  border: 1px solid #00acfb;
  border-radius: 8px;
  justify-content: center;
}

.logout-btn:hover {
  cursor: pointer;
  /* border: 4px solid #00ACFB; */
  box-shadow: 4px 3px 5px rgb(180, 178, 178);
}

.signoutSVG {
  height: 20px;
  width: 20px;
}

.optionsSVG {
  width: 24px;
  height: 24px;
}

.userName {
  font-size: 16px;
}
/* .navs:hover .optionsSVG{
    filter: brightness(100);

  } */

/* .options{
    display: flex;
    flex-direction: row;
    align-items: center;
  } */
