.dashright {
  margin-left: auto;
  width: calc(100% - 250px);
  padding: 72px 36px;
  min-height: 100vh;
}
/* .chanDets{
    height:92vh;
    background-color: white;
} */

.main-area {
  /* height: 100vh; */
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* padding: 1%; */
}

/* .table-area{
    margin-top:10px;
    width:80%;
    margin-left: 5px;
    margin-right: 5px;
}

.adds{
    width:20%;
    align-items: center;
    background-color: rgb(182, 182, 183);
}

.box{
    margin: 10px;
    background-color: white;
    
}

.table{
    background-color: white;
    margin-top: 4%
}

.btn{
    color: white;
} */
.heading {
  font-family: 'Roboto', 'sans-serif';
  font-size: 4vh;
  font-weight: bolder;
  color: #223036;
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  height: 10%;
  /* padding: 20px; */
}

.left-head {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5vw;
  margin-right: auto;
}

/* .right-head{
    display: flex;
    flex-direction: row;
    gap:1vw;
    align-items:center;
    margin-left: auto;
} */

.searchBox {
  border: 3px solid #ededef;
  height: 5vh;
}

.filterSearch {
  /* width: 4vw;
    height: 4vh; */
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  padding: 8px;
  border-radius: 0.5vh;
  height: fit-content;
  background-color: #ffffff;
  color: #00acfb;
  border: 2px solid #00acfb;
}

.filterSearch:hover {
  background-color: #00acfb;
  color: #ffffff;
}

.right-head {
  display: flex;
  flex-direction: row;
  gap: 2%;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  margin-right: 1%;
}

.table_date_filter_div {
  display: flex;
  flex-direction: column;
}

.box {
  background-color: #223036;
}

.datepicker_dropdown {
  z-index: 99;
}

/* 

.box{
    display: flex;
    flex-direction: row;
    gap: 0.2vw;
}

.datePickers{
    display: flex;
    flex-direction: row;
    width:fit-content;
} */
/* */
.filterDate {
  width: max-content;
  border-radius: 0.5vh;
  background-color: #ffffff;
  color: #00acfb;
  border: 2px solid #00acfb;
  font-size: smaller;
}

.filterDate:hover {
  color: #ffffff;
  background-color: #00acfb;
}

.welcomeUser {
  background: #ebf9ff;
  font-family: 'Roboto', 'sans-serif';
  color: #00acfb;
  width: fit-content;
  justify-content: center;
  font-size: large;
  padding-inline: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.applyFilter {
  width: auto;
  border-radius: 1vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.table-area {
  /* padding: 2%; */
  height: 90%;
  display: flex;
  flex-direction: column;

  /* align-items: center; */
}

/* .table_container { */
  /* width: 100%; */ 
/* } */

.display_pages {
  color: #b7b7b7;
}

.pagination_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  padding: 2vh;
  margin-top: 0.5%;
}

.nav_container {
  margin-left: auto;
}

.numberBox {
  width: 3vw;
}

.entries {
  margin-left: auto;
}

.actionsIMG {
  height: 4vh;
  width: auto;
}
.tags {
  border: 2px solid #00acfb;
  display: flex;
  width: max-content;
  overflow: hidden;
  justify-content: center;
  border-radius: 4px;
  padding: 4px;
  color: #00acfb;
}

.tags:hover {
  cursor: grabbing;
}

.channel_link {
  color: black;
  text-decoration: none;
  font-family: 'Verdana, Geneva, Tahoma, sans-serif';
}

.channel_link:hover {
  text-decoration: underline;
}

.TagsPopupMultiple {
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
