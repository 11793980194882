.page_new {
  margin-left: auto;
  width: calc(100% - 250px);
  padding: 20px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: var(--font-roboto);
  gap: 2.5vh;
}

.page_new .header {
  font-size: xx-large;
  font-weight: bolder;
  font-family: var(--font-roboto);
  font-weight: 600;
}

.page_new .analytics_1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2.5vh;
}

.page_new .analytics_1 .graph_1 {
  width: 65%;
  padding: 10px;
  -webkit-box-shadow: 2px 2px 24px 0px #0000001F;
          box-shadow: 2px 2px 24px 0px #0000001F;
  border-radius: 10px;
}

.page_new .analytics_1 .graph_1 .graph_1_title {
  font-weight: bold;
  font-family: var(--font-roboto);
  text-align: center;
  margin-bottom: 3vh;
  margin-top: 1vh;
}

.page_new .analytics_1 .graph_1 .graph_1_line_chart_container {
  height: 30vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.page_new .analytics_1 .graph_2 {
  width: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2.5vh;
}

.page_new .analytics_1 .graph_2 .graph_2_child {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-box-shadow: 2px 2px 24px 0px #0000001F;
          box-shadow: 2px 2px 24px 0px #0000001F;
  border-radius: 10px;
  position: relative;
}

.page_new .analytics_1 .graph_2 .graph_2_child .graph_2_child_data {
  font-size: 40px;
  color: #00ACFB;
  font-weight: bold;
  position: absolute;
  bottom: 25%;
  text-align: center;
  width: 100%;
}

.page_new .analytics_1 .graph_2 .graph_2_content_1 {
  padding: 10px;
}

.page_new .analytics_1 .graph_2 .graph_2_content_1 .graph_2_content_1_title {
  font-weight: bold;
  margin-top: 1vh;
}

.page_new .analytics_1 .graph_2 .graph_2_content_2 {
  padding: 10px;
}

.page_new .analytics_1 .graph_2 .graph_2_content_2 .graph_2_content_2_title {
  font-weight: bold;
  margin-top: 1vh;
}

.page_new .analytics_2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2.5vh;
}

.page_new .analytics_2 .analytics_2_child {
  width: 100%;
  -webkit-box-shadow: 2px 2px 24px 0px #0000001F;
          box-shadow: 2px 2px 24px 0px #0000001F;
  border-radius: 10px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.page_new .analytics_2 .analytics_2_child .analytics_2_child_title {
  font-weight: bold;
  margin-bottom: 3vh;
  margin-top: 1vh;
  text-align: center;
}

.page_new .analytics_2 .analytics_2_graph_1 {
  width: 25%;
}

.page_new .analytics_2 .analytics_2_graph_1 .analytics_2_graph_1_data {
  width: 100%;
  height: 25vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* Center horizontally */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* Center vertically */
}

.page_new .analytics_2 .analytics_2_graph_2 {
  width: 50%;
}

.page_new .analytics_2 .analytics_2_graph_2 .analytics_2_graph_2_data {
  width: 100%;
  height: 25vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.page_new .analytics_2 .analytics_2_graph_3 {
  width: 25%;
}

.page_new .analytics_2 .analytics_2_graph_3 .analytics_2_graph_3_data {
  width: 100%;
  height: 25vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.page_new .analytics_3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2.5vh;
}

.page_new .analytics_3 .analytics_3_child {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-shadow: 2px 2px 24px 0px #0000001F;
          box-shadow: 2px 2px 24px 0px #0000001F;
  border-radius: 10px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.page_new .analytics_3 .analytics_3_child .analytics_3_child_title {
  font-weight: bold;
  margin-top: 1vh;
  text-align: center;
}

.page_new .analytics_3 .analytics_3_child .analytics_3_child_data {
  font-size: 40px;
  font-weight: bold;
  color: #00ACFB;
}
