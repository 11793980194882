/* .page{
    height: 100vh;
    padding: 2%;
    margin-left: 13%;
} */
/* .title{
    margin-top: 1.3%;
    height: 10%;
    font-size: xx-large;
    font-weight: bolder;
} */

/* .tab_container{
    height: 80%;
    display: flex;
   
}

.tab{
    height: 100%;
    width: 100%;
    padding: 1%;
    background-color: #898F9A;

}

.tabSelector{
    display: flex;
    border-bottom: 1px solid black;
}

.tabBtn{
    background-color: white;
    color: #898F9A;
    border: 1px solid #898F9A;
    width: fit-content;
    font-size: large;

}


.tabHead{
    display: flex;
    align-items: center;
    background-color: blanchedalmond;
    padding: 1%;
    gap: 40%;
}

.keywordInputContainer{
    display: flex;
    gap :1%;
    background-color: white;
    width: 60%;
}

.tabBodyKeyword{
    height: 80%;
    background-color: cadetblue;
}
.AllKeywords{
    overflow: auto;
    height: 100%;
    width: 50%;
    background-color: rgb(239, 151, 133);
    border: 1px solid #898F9A;
    padding: 1%;
}

.keywordsDisplay{
    background-color: rgb(216, 170, 238);
    display: flex;
    justify-items: stretch;
    height: 90%;
}


.monitorKeywords{
    background-color: white;
    width: 50%;
    height: 100%;
    padding: 1%;
    border: 1px solid #898F9A;
}

.tabHeading{
    font-size: large;
    font-weight: bolder;
}

.AddKeywordSearchBox{
    width: 100%;
    border: 2px solid #898F9A;
    border-radius: 6px;
}

.addKeywordBtn{
    width: fit-content;
    padding-left: 2%;
    padding-right: 2%;
    font-size: medium;
    font-weight: bold;
   background-color: rgb(23, 148,  23);
}

.right-head{
    width: 28%;
    
}

.finalKeywordBtn{
    width: 40%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 6px;
}

.welcomeUser{
    width: 50%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 6px;
    background-color:#EBF9FF;
    color: #00ACFB;
} */

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: 'rgba(0, 0, 0, 0.5)';
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
}

.modalContent {
  background-color: 'white';
  padding: '20px';
  border-radius: '10px';
}

.headBar {
  display: flex;
  gap: 1%;
  align-items: center;
}

.Head1 {
  font-size: xx-large;
  font-weight: bolder;
}

.successBtn {
  width: 6%;
  font-size: 130%;
  border-radius: 10px;
}

.inputChannel {
  width: 30%;
  height: 5vh;
}

.finalKeywordBtn {
  margin-left: auto;
  height: fit-content;
  width: fit-content;
  color: white;
  background-color: white;
  color: #00acfb;
  border: 1px solid #00acfb;
  padding: 16px 24px;
  border-radius: 8px;
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 500;
}

.userBtn {
  font-size: 130%;
  border-radius: 10px;
  width: 15%;
  background-color: #ebf9ff;
  color: #00acfb;
}

.pageBody {
  margin-top: 2vh;
}

.centerHead {
  display: flex;
  justify-content: center;
  font-size: xx-large;
  font-weight: bold;
}

.subHeads {
  font-weight: bold;
  margin-top: 3%;
}
.searchBar {
  display: flex;
}
.keySearch {
  min-width: 80%;
  background-color: #f7f9fb;
}

.addKeywordBtn {
  margin-left: auto;
}

.deleteTagBtn {
  font-size: x-small;
  color: rgb(141, 7, 7);
  background-color: white;
  height: 2px;
}

.allKeys {
  overflow: auto;
  margin-bottom: 3vh;
}

.keys {
  background: #ececec;
  border: 2px solid #fdfdfd;
  border-radius: 4px;
  width: fit-content;
  padding-inline: 15px;
  padding-block: 2px;
  text-align: center;
}

.keys:hover {
  cursor: pointer;
}

.keyList {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); */
}
.btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
}

.modalBtnConfirm {
  width: 30%;
  font-size: 130%;
  border-radius: 10px;
}

.modalBtnCancel {
  width: 30%;
  font-size: 130%;
  border-radius: 10px;
  background-color: white;
  color: #7b8fa2;
  border: 1px solid #7b8fa2;
}

.ruleContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 22px;
  gap: 20px;
}

.ruleBox {
  width: 49%;
  padding: 24px;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: #f7f9fb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  scrollbar-color: #00ACFB;
  overflow-x: hidden;
}

.ruleHead {
  font-size: 20px;
  font-weight: 500;
  color: black;
}

.ruleSubHead {
  font-weight: 600;
  font-size: 16px;
  margin-top: 14px;
}

.ruleContent {
  margin-top: 5%;
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.ruleButtons {
  margin-top: 5%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 4%;
  margin-bottom: 5%;
}

.ruleDate {
  color: #b7b7b7;
  font-size: medium;
  font-weight: bolder;
  margin-top: -3%;
}

.ruleBtn1 {
  border: 1px solid #7b8fa2;
  color: #7b8fa2;
  background-color: white;
  border-radius: 8px;
  width: fit-content;

  font-size: 16px;
  height: auto;
  padding: 12px 16px;
}

.ruleBtn2 {
  border: 1px solid #ff9458;
  color: #ff9458;
  background-color: white;
  border-radius: 8px;
  width: fit-content;

  font-size: 16px;
  height: auto;
  padding: 12px 16px;
}

.ruleKeys {
  /* margin-top: 5%; */
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

}

.ruleKeys::-webkit-scrollbar {
  background-color: var(--color-background-blue);
  width: 5px;
}
.ruleKeys::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-blue);
  border-radius: 4px;
}

.ruleKeys > .tags {
  padding: 4px 8px;
}

.tabsContainer {
  display: flex;
  gap: 24px;
}
.tabSelectors {
  width: fit-content;
  font-size: 20px;
  padding: 10px;
  height: max-content;
  font-weight: 600;
  background: white;
  color: #223036;
  border-radius: 8px;
}

.tabSelectors:focus {
  background-color: #ebf9ff;
  color: #00acfb;
}

.ruleSubCardContainer {
  margin-top: auto;
  /* margin-top: 1.5vh; */

  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 10px;
  max-height: 30vh;
  overflow-y: scroll;
}
.ruleSubcard {
  display: flex;
  flex-direction: column;
}

.channelNameTags {
  color: #d1c51a;
  font-weight: bolder;
  border: 1px solid #d1c51a;
  border-radius: 4px;
  padding: 4px;
}

.fileTags {
  color: #b69cff;
  font-weight: bolder;
  border: 1px solid #b69cff;
  border-radius: 4px;
  padding: 4px;
}

.ruleDescription {
  margin-bottom: 1vh;
}

.multiInputBar {
  width: 100%;
  min-height: 5vh;
  border: 1px solid #B7B7B7;
  background-color: white;
  outline: none;
  padding-left: 10px;
  border-radius: 10px;
  margin-top: 5px;
}

.viewChannelBtn {
  background: #00acfb;
  border-radius: 4px;
  width: max-content;
  height: min-content;
  padding: 4px;
  font-size: medium;
  padding-block: 8px;
}

.trash-icon:hover {
  content: url(../images/redTrash.svg);
}
