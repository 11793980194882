@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
:root {
  --font-roboto: 'Roboto', sans-serif;
  --modal-backdrop-background-color: rgba(57, 57, 57, 0.8);
  --color-primary-blue: #00acfb;
  --color-background-blue: #ebf9ff;
}

body {
  margin: 0;
  font-family: var(--font-roboto);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dashboard {
  display: flex;
}

.datepicker_dropdown .rdrDefinedRangesWrapper {
  display: none;
}

.datepicker_dropdown .rdrCalendarWrapper .rdrDateDisplayWrapper {
  display: none;
}

.backdropModal {
  background-color: var(--modal-backdrop-background-color);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterBackdropModal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 9999999;
    top: 0;
    left: 0;
    padding-top: 5%;
    padding-right: 15%;

    
}
.filterBackdropModalMonitor{
  width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 9999999;
    top: 0;
    left: 0;
    padding-top: 4.5%;
    padding-right: 14%;
}
.filterBackdropModalChannels{
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 9999999;
  top: 0;
  left: 0;
  padding-top: 5%;
  padding-right: 26%;
}
.filterbox{
  padding: 0.8%;
  background-color: white;
  border-radius: 5%;
  box-shadow: 0px 5px 41px 0px #00000024;
  width: 300px;
}
.scrollbar::-webkit-scrollbar {
  background-color:transparent;
  width:16px;
  
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color:transparent;
  border-radius:'100px';

}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color:transparent
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:5px solid #fff
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {display:none}

/* div box */

.scrollbar {
  /* height: 75vh; */
  width: 25vw;
  min-width: 150px;
  background: #fff;
  overflow-y: scroll}
.overflow{min-height: 100vh}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}