.page {
  margin-left: auto;
  width: calc(100% - 250px);
  padding: 72px 36px;
}

.Row1 {
  /* margin-top: 2%; */
  display: flex;
  align-items: center;
  gap: 2%;
}

.Row2 {
  margin-top: 4%;
  display: flex;
  align-items: center;
  gap: 2%;
}

.Row3 {
  margin-top: 4%;
}

.Head {
  font-size: xx-large;
  font-weight: bolder;
  font-family: var(--font-roboto);
  font-weight: 600;
}

.searchContainer {
  width: 45%;
  display: flex;
  gap: 8px;
  align-items: center;
}

.entryBox {
  width: 80%;
  border: 1px solid #ededef;
  z-index: 3;
}

.filterApplyBtn {
  width: fit-content;
  height: max-content;
  border-radius: 8px;
  padding-inline: 8px;
  background-color: rgb(9, 101, 9);
}

.welcomeUserButton {
  background-color: #ebf9ff;
  font-weight: bold;
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 16px 24px;
  border-radius: 4px;
}

.welcomeUserButton > * {
  background-color: #ebf9ff;
  color: #00acfb;
}

.welcomeUserButton > button {
  height: auto;
  font-size: 20px;
}

.analyticsCard {
  display: flex;
  gap: 6%;
  width: 25%;
  border: 1px solid #ededef;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  align-items: center;
  padding: 2.5%;
}

.cardHead {
  font-size: large;
  font-weight: bolder;
}

.cardNumber1 {
  font-size: xx-large;
  font-weight: bolder;
  color: #9cdbff;
}

.cardNumber2 {
  font-size: xx-large;
  font-weight: bolder;
  color: #9cb8ff;
}

.cardNumber3 {
  font-size: xx-large;
  font-weight: bolder;
  color: #2ce38b;
}

.cardNumber4 {
  font-size: xx-large;
  font-weight: bolder;
  color: #e59cff;
}

.Filters {
  display: flex;
  padding: 2%;
}

.filterBtn {
  margin-left: auto;
  display: flex;
  justify-content: end;
  width: 30%;
  gap: 2%;
}

.selectBtn {
  background-color: white;
  color: #898f9a;
  width: fit-content;
  border: 1px solid #898f9a;
  font-size: medium;
  padding: 2%;
}

.FilterHead {
  font-size: xx-large;
  font-weight: bolder;
}
