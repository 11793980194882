.notificationContainer {
  margin-left: auto;
}

.keywordButton {
  background-color: white;
  width: fit-content;
}

.tabSelector {
  display: flex;
  margin-top: 3vh;
}

.tabBtn {
  background-color: white;
  color: #223036;
  width: fit-content;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  height: fit-content;
  border-radius: 8px;
}

.notificationBar:hover {
  cursor: pointer;
  background-color: #c4cdd53c;
}

.btn_create_rule {
  margin-left: auto;
  height: fit-content;
  width: fit-content;
  color: white;
  background-color: white;
  color: #00acfb;
  border: 1px solid #00acfb;
  padding: 16px 24px;
  border-radius: 8px;
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 500;
}

.react-multivalue-text-input-values {
  display: none;
}

/* .Box {
  margin-top: 1vh;
  margin-left: 25%;
} */

.table-container {
  border: 1px solid #c4cdd5;
  margin-top: 2vh;
  overflow-x: scroll;
  height: 656px;
  overflow-y: scroll;
}

td {
  padding-block: 10px;
  padding-inline: 10px;
  border-bottom: 1px solid #c4cdd5;
}

.notificationHeading {
  display: flex;
  align-items: baseline;
  gap: 2%;
}

.notificationDropdown {
  position: absolute;
  background-color: white;
  padding: 10px;
  margin-left: -9.1%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 1%;
  margin-top: 0.5%;
  overflow-y: scroll;
  max-height: 40%;
}

.notificationHead {
  color: black;
  font-size: x-large;
  text-align: left;
  padding-block: 6px;
  font-weight: bolder;
}

.notificationTitle {
  font-size: large;
  font-weight: bolder;
  color: black;
}

.notificationContent {
  font-size: medium;
  color: #898f9a;
  text-align: left;
}

.notificationCloseIcon {
  margin-left: auto;
}

/* #keywordsAdd {
  position: fixed;
  margin-inline: -3.5%;
  margin-block: -24.16%;
} */

.nameInputBox {
  width: 100%;
  height: 5vh;
  border: 1px solid #B7B7B7;
  background-color: white;
  outline: none;
  padding-left: 10px;
  border-radius: 10px;
  margin-top: 5px;
}

.descriptionInputBox {
  width: 100%;
  height: 10vh;
  border: 1px solid #B7B7B7;
  background-color: white;
  outline: none;
  padding-left: 10px;
  border-radius: 10px;
  margin-top: 5px;
  text-align: start;
}

.add_KeywordBtn {
  width: 100%;
  padding-inline: 10px;
  border-radius: 0px;
  border: 1px solid black;
  background-color: #c4cdd5;
  font-size: larger;
  margin-top: 1%;
}

.finalising_div {
  margin-top: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
}

.btnCreate {
  width: fit-content;
  padding-inline: 4%;
  border-radius: 6px;
  font-size: large;
  height: fit-content;
  padding-block: 6px;
  background-color: #00acfb;
}
.btnCreate:hover {
  color: #00acfb;
  background-color: white;
  border: 1px solid #00acfb;
}

.btnCancel {
  width: fit-content;
  padding-inline: 4%;
  border-radius: 6px;
  background-color: white;
  border: 1px solid #c4cdd5;
  color: #898f9a;
  font-size: large;
  height: fit-content;
  padding-block: 6px;
}

.btnCancel:hover {
  color: white;
  background-color: #898f9a;
}

.displayKey {
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap;
  border: 3px solid #c4cdd5;
  border-radius: 4px;
  padding-block: 2%;
  height: 10vh;
  padding-inline: 2%;
  overflow-y: scroll;
  background-color: #f7f9fb;
}

.ruleMediaBox {
  margin-top: 2%;
  width: 100%;
  overflow-y: scroll;
  background-color: white;
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
}

.paginate_scroll {
  /* position: sticky; */
  margin-top: auto;
  background-color: white;
  width: 100%;
  display: flex;
  margin-bottom: 1%;
  bottom: 0;
}

.paginate_contained {
  margin-left: auto;
}

.multiple-value-text-input-item-container{
  max-height: 6vh;
  overflow-y: scroll;
}