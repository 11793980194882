.td {
  display: flex;
  align-items: center;
}

.table-container {
  margin-top: 7vh;
  overflow-x: scroll;
  height: 550px; /* set a fixed height */
  overflow-y: scroll; /* enable vertical scrolling */
}

.table {
  border: 1px solid #c4cdd5;
}

.notificationSymbol {
  padding: 12px;
}
.content {
  padding-inline: 2px;
  display: flex;
  gap: 2vh;
}

.content_title {
  font-weight: bolder;
  font-size: large;
}

.content_description {
  color: #666666;
  font-size: medium;
}

.content_date {
  color: #bdbdbd;
}
