.login {
  font-family: 'Roboto', sans-serif;
}
.upperCylinder {
  height: 50%;
}

.upperBean {
  position: fixed;
  width: 103%;
  height: 90%;
  left: 14%;
  top: -59%;
  background: linear-gradient(243.97deg, #3ac4ff 46.26%, #00acfb 81.83%);
  border-radius: 446.546px;
  transform: rotate(-30deg);
}

.lowerBean {
  position: fixed;
  width: 95%;
  height: 90%;
  left: -14%;
  top: 79%;
  background: linear-gradient(243.97deg, #3ac4ff 46.26%, #00acfb 81.83%);
  border-radius: 446.546px;
  transform: rotate(-30deg);
}

.frame {
  margin-top: 18vh;
  background-color: #ffffff;
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4%;
  width: 36%;
  min-height: 58vh;
  left: 30%;
  border-radius: 44px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.12);
}

.frameBox {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 5vh;
}

h1 {
  margin-top: 5%;
  /* font-family: Roboto; */
  font-size: 450%;
  font-weight: 900;
  line-height: 20px;
}

.subhead {
  color: #9d9d9d;
  margin-top: 10%;
}

.frameItem1 {
  text-align: center;
}

.frameItem2 {
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.inputBox {
  background-color: #f6f6f6;
  width: 100%;
  height: 6vh;
  border: none;
  padding-left: 1vw;
  padding-right: 1vw;
  font-size: larger;
  /* font-weight: 600; */
}

.inputPasswordContainer {
  position: relative;
}

.inputPasswordContainer > .eyeLogin {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.inputPasswordContainer > .eyeLogin:hover {
  cursor: pointer;
}
h6 {
  font-weight: 600;
}
.inputBox:hover {
  border: 1px solid #00acfb;
}

.inputBox:focus-visible {
  outline: none;
  border: 2px solid #00acfb;
}

.password {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

button {
  width: 100%;
  height: 5vh;
  background-color: #00acfb;
  color: #ffffff;
  border: none;
  font-size: x-large;
  font-weight: 500;
}

.form-check-input {
  margin-right: 8px;
}
.remember {
  margin: 2%;
  font-size: 90%;
}

.forgotPassword {
  font-size: 90%;
}
