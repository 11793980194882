.field_container {
  display: flex;
  width: 100%;

  gap: 1%;
  align-items: center;
}

.multi_field_column {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.field_head {
  font-weight: bold;
  font-size: medium;
  width: 10%;
}

.field_head_multi {
  font-weight: bold;
  font-size: medium;
}
.field_head_multi_first {
  font-weight: bold;
  font-size: medium;
  width: 22%;
}
.field_value {
  font-size: medium;
  color: #666;
}

.field_head_slack {
  font-weight: bold;
  font-size: medium;
  width: 20%;
}
