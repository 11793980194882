.page_new {
    margin-left: auto;
    width: calc(100% - 250px);
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    font-family: var(--font-roboto);
    gap: 2.5vh;
    .header{
      font-size: xx-large;
      font-weight: bolder;
      font-family: var(--font-roboto);
      font-weight: 600;
    }
    .analytics_1{
      // margin-top: 1.5vh;
      display: flex;
      gap: 2.5vh;
      .graph_1{
        // border: 1px solid;
        width: 65%;
        padding: 10px;
        box-shadow: 2px 2px 24px 0px #0000001F;
        border-radius: 10px;
        .graph_1_title{
          font-weight: bold;
          font-family: var(--font-roboto);
          text-align: center;
          margin-bottom : 3vh;
          margin-top: 1vh;
        }
        .graph_1_line_chart_container{
          height: 30vh;
          display: flex;
          // align-items: center;
          justify-content: center;
        }
      }
      .graph_2{
        // border: 1px solid;
        width: 35%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // padding: 10px;
        gap: 2.5vh;
        .graph_2_child{
          flex: 1;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          text-align: center;
          box-shadow: 2px 2px 24px 0px #0000001F;
          border-radius: 10px;
          position: relative;
          .graph_2_child_data{
            font-size: 40px;
            color: #00ACFB;
            font-weight: bold;
            position: absolute;
            bottom: 25%;
            text-align: center;
            width: 100%;
          }
        }
        .graph_2_content_1{
          padding: 10px;
          .graph_2_content_1_title{
            font-weight: bold;
          margin-top: 1vh;
          }
        }
        .graph_2_content_2{
          padding: 10px;
          .graph_2_content_2_title{
            font-weight: bold;
          margin-top: 1vh;
          }
        }
      }
    }
    .analytics_2{
      // margin-top: 1.5vh;
      display: flex;
      gap: 2.5vh;
      .analytics_2_child{
        width: 100%;
        // flex: 1;
        box-shadow: 2px 2px 24px 0px #0000001F;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        .analytics_2_child_title{
          font-weight: bold;
          margin-bottom: 3vh;
          margin-top: 1vh;
          text-align: center;
        }
      }
      .analytics_2_graph_1{
        width: 25%;
        .analytics_2_graph_1_data{
          width: 100%;
          height: 25vh;
          display: flex;
          justify-content: center; /* Center horizontally */
          align-items: center; /* Center vertically */
        }
      }
      .analytics_2_graph_2{
        width: 50%;
        .analytics_2_graph_2_data{
          width: 100%;
          height: 25vh;
          display: flex;
          // align-items: center;
          justify-content: center;
        }
      }
      .analytics_2_graph_3{
        width: 25%;
        .analytics_2_graph_3_data{
          width: 100%;
          height: 25vh;
          display: flex;
          // align-items: center;
          justify-content: center;
        }
      }
    }
    .analytics_3{
      display: flex;
      gap: 2.5vh;
      .analytics_3_child{
        flex: 1;
        box-shadow: 2px 2px 24px 0px #0000001F;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .analytics_3_child_title{
          font-weight: bold;
          margin-top: 1vh;
          text-align: center;
          // margin-bottom: 1vh;
        }
        .analytics_3_child_data{
          font-size: 40px;
          font-weight: bold;
          color: #00ACFB;
        }
      }
    }
  }
